import type { ScreenType } from '@gem/common';
import type { Component } from '../../common/utils/types';
import type { GroupTypeSetting } from '@gem/control';

const SCALE_FACTORS: Record<string, any> = {
  headingSize: {
    tablet: {
      scale: 1,
    },
    mobile: {
      startResponsive: '24px',
      minValue: '24px',
      maxValue: '40px',
      scale: 0.8,
      jump: 4,
    },
  },
  paragraphSize: {
    tablet: {
      scale: 1,
    },
    mobile: {
      startResponsive: '15px',
      minValue: '15px',
      maxValue: '20px',
      scale: 0.9,
    },
  },
  spacing: {
    left: {
      tablet: {
        scale: 1,
      },
      mobile: {
        startResponsive: '24px',
        minValue: '24px',
        maxValue: '24px',
        scale: 1,
      },
    },
    top: {
      tablet: {
        scale: 1,
      },
      mobile: {
        startResponsive: '40px',
        minValue: '40px',
        maxValue: '40px',
        scale: 1,
      },
    },
    right: {
      tablet: {
        scale: 1,
      },
      mobile: {
        startResponsive: '24px',
        minValue: '24px',
        maxValue: '24px',
        scale: 1,
      },
    },
    bottom: {
      tablet: {
        scale: 1,
      },
      mobile: {
        startResponsive: '40px',
        minValue: '40px',
        maxValue: '40px',
        scale: 1,
      },
    },
  },
};

function roundToJump(number: number, jump: number) {
  // làm tròn theo logic ví dụ jump = 4 thì 40 -> 32, 48 -> 36, 34 -> 36
  const lower = Math.floor(number / jump) * jump;
  const upper = lower + jump;
  return number - lower < jump / 2 ? lower : upper;
}

const getValue = (
  val: string,
  option: {
    scale: number | undefined;
    startResponsive?: string;
    minValue?: string;
    maxValue?: string;
    jump?: number;
  },
) => {
  if (val?.toString()?.includes('--g-s-')) return val;
  const { scale, startResponsive, minValue, maxValue, jump } = option;
  const fVal = parseFloat(val);
  const fStartResponsive = parseFloat(startResponsive ?? '0');
  const fMinValue = parseFloat(minValue ?? '0');
  const fMaxValue = parseFloat(maxValue ?? '0');

  if ((startResponsive && fVal <= fStartResponsive) || !scale) {
    return val;
  }
  const newValue = fVal * scale;
  if (minValue && newValue <= fMinValue) {
    return minValue;
  }

  if (maxValue && newValue > fMaxValue) {
    return maxValue;
  }
  if (scale === 1) {
    return `${newValue}px`;
  }
  if (jump) {
    return `${roundToJump(parseFloat(newValue.toFixed(2)), jump)}px`;
  }
  return `${Math.ceil(parseFloat(newValue.toFixed(2)) || 0)}px`;
};

function removeEmptyObjects(obj: Record<string, any>) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeEmptyObjects(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
}

const getSpacingConfigValue = (config: any, position: string, screenId: ScreenType) => {
  return config?.[position]?.[screenId];
};

const buildSpacingObject = (spacingValue: any, config: any, type: ScreenType) => {
  if (!spacingValue) return undefined;

  return ['bottom', 'left', 'right', 'top'].reduce((acc, direction) => {
    if (spacingValue[direction]) {
      acc[direction] = getValue(spacingValue[direction], getSpacingConfigValue(config, direction, type));
    }
    return acc;
  }, {} as Record<string, any>);
};

export default () => {
  const handleResponsiveSetting = (
    currentComponent: Component,
    setting: {
      controlType: string;
      newValue: any;
      screenId: ScreenType;
      hasDevices?: boolean;
      groupType: GroupTypeSetting;
      controlId: string;
    },
  ) => {
    const { controlId, screenId, newValue, groupType } = setting;
    if (!currentComponent) return;

    let settingType: 'styles' | 'settings' | 'advanced' = 'advanced';
    switch (groupType) {
      case 'style': {
        settingType = 'styles';
        break;
      }
      case 'setting': {
        settingType = 'settings';
        break;
      }
    }

    const value = currentComponent?.[settingType]?.[controlId];
    if (!value) return;

    switch (controlId) {
      case 'spacing-setting': {
        const config = SCALE_FACTORS['spacing'];
        let desktopValue = value.desktop;
        let tabletValue = value.tablet;
        let mobileValue = value.mobile;
        const edited = value.edited ?? [];
        if (!edited?.length) {
          if (tabletValue) {
            edited.push('tablet');
          }
          if (mobileValue) {
            edited.push('mobile');
          }
        }
        if (screenId === 'desktop') {
          desktopValue = newValue;
          const marginValue = newValue?.margin || {};
          const paddingValue = newValue?.padding || {};
          if (!edited.includes('desktop')) {
            edited.push('desktop');
          }
          if (!edited.includes('tablet')) {
            tabletValue = {
              ...tabletValue,
              ...(marginValue
                ? {
                    margin: buildSpacingObject(marginValue, config, 'tablet'),
                  }
                : undefined),
              ...(paddingValue
                ? {
                    padding: buildSpacingObject(paddingValue, config, 'tablet'),
                  }
                : undefined),
            };
            if (!edited.includes('mobile')) {
              mobileValue = {
                ...mobileValue,
                ...(tabletValue.margin
                  ? {
                      margin: buildSpacingObject(tabletValue.margin, config, 'mobile'),
                    }
                  : undefined),
                ...(tabletValue.padding
                  ? {
                      padding: buildSpacingObject(tabletValue.padding, config, 'mobile'),
                    }
                  : undefined),
              };
            }
          }
        } else if (screenId === 'tablet') {
          tabletValue = newValue;
          if (!edited.includes('tablet')) {
            edited.push('tablet');
          }
          if (!edited.includes('desktop')) {
            desktopValue = {
              ...desktopValue,
              ...newValue,
            };
          }
          if (!edited.includes('mobile')) {
            mobileValue = {
              ...mobileValue,
              ...(tabletValue.margin
                ? {
                    margin: buildSpacingObject(tabletValue.margin, config, 'mobile'),
                  }
                : undefined),
              ...(tabletValue.padding
                ? {
                    padding: buildSpacingObject(tabletValue.padding, config, 'mobile'),
                  }
                : undefined),
            };
          }
        } else {
          mobileValue = newValue;
          if (!edited.includes('mobile')) {
            edited.push('mobile');
          }
          if (!edited.includes('desktop')) {
            desktopValue = {
              ...desktopValue,
              ...newValue,
            };
            if (!edited.includes('tablet')) {
              tabletValue = {
                ...tabletValue,
                ...newValue,
              };
            }
          }
        }
        const newSpacing = removeEmptyObjects({
          edited,
          desktop: desktopValue,
          tablet: tabletValue,
          mobile: mobileValue,
        });
        currentComponent![settingType]![controlId] = newSpacing;
        break;
      }
      case 'typo': {
        if (!value?.custom?.fontSize) return;
        const typeSize = value.type.includes('heading') ? 'headingSize' : 'paragraphSize';
        const config = SCALE_FACTORS[typeSize];
        const tabletConfig = config['tablet'];
        const mobileConfig = config['mobile'];
        const edited = newValue?.custom?.fontSize?.edited ?? [];
        let desktopValue = value.custom?.fontSize?.desktop;
        let tabletValue = value.custom?.fontSize?.tablet;
        let mobileValue = value.custom?.fontSize?.mobile;
        if (screenId === 'desktop') {
          desktopValue = newValue.custom.fontSize.desktop;
          if (!edited.includes('desktop')) {
            edited.push('desktop');
          }
          if (!edited.includes('tablet')) {
            tabletValue = getValue(desktopValue, { ...tabletConfig });
            if (!edited.includes('mobile')) {
              mobileValue = getValue(tabletValue, { ...mobileConfig });
            }
          }
        } else if (screenId === 'tablet') {
          tabletValue = newValue.custom.fontSize.tablet;
          if (!edited.includes('tablet')) {
            edited.push('tablet');
          }
          if (!edited.includes('desktop')) {
            desktopValue = tabletValue;
          }
          if (!edited.includes('mobile')) {
            mobileValue = getValue(tabletValue, { ...mobileConfig });
          }
        } else {
          mobileValue = newValue.custom.fontSize.mobile;
          if (!edited.includes('mobile')) {
            edited.push('mobile');
          }
          if (!edited.includes('desktop')) {
            desktopValue = mobileValue;
            if (!edited.includes('tablet')) {
              tabletValue = mobileValue;
            }
          }
        }
        const newFontSize = {
          ...newValue.custom.fontSize,
          edited,
          desktop: desktopValue,
          tablet: tabletValue,
          mobile: mobileValue,
        };
        currentComponent![settingType]![controlId] = {
          ...value,
          custom: {
            ...value.custom,
            fontSize: newFontSize,
          },
        };
      }
    }
  };

  return {
    handleResponsiveSetting,
  };
};
